import React from 'react'
import Search from '.'
import useSiteMetadata from '../../hooks/use-site-metadata'

export default function SiteSearch() {
  const { contentPackages } = useSiteMetadata()
  const searchablePackages = contentPackages.filter(
    ({ includeInSiteSearch }) => includeInSiteSearch === true
  )
  const options = {
    filters: [
      'language:en',
      'isLatestVersion:true',
      `(${searchablePackages.map(({ id }) => `product:${id}`).join(' OR ')})`,
    ].join(' AND '),
  }

  return <Search modalTitle={'Search All Documentation'} options={options} />
}
