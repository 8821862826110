import * as React from 'react'
import isBrowser from '../lib/is-browser'
import Header from '../components/Header'
import Footer from '../components/Footer'
import './404.scss'
import SiteSearch from '../components/Search/SiteSearch'
import useSiteMetadata from '../hooks/use-site-metadata'
import { Helmet } from 'react-helmet'
import createDocumentTitle from '../lib/document-title'
import Logo from '../components/Header/Logo'
import ContactUs from '../components/Header/ContactUs'

export default function NotFoundPage() {
  if (!isBrowser()) {
    return null
  }

  const { title: siteTitle } = useSiteMetadata()
  const pageTitle = 'Page Not Found'

  return (
    <>
      <Helmet>
        <title>{createDocumentTitle(siteTitle, pageTitle)}</title>
      </Helmet>
      <div className="NotFoundPage">
        <Header centerAlign>
          <div className="cluster--left">
            <Logo />
          </div>
          <div className="cluster--right">
            <ContactUs />
          </div>
        </Header>
        <div className="Content center-align">
          <div>
            <h1>{pageTitle}</h1>
            <p>
              The page you asked for could not be found. Double-check the URL,
              or try a search.
            </p>
            <div className="Search">
              <SiteSearch />
            </div>
          </div>
        </div>
        <Footer centerAlign />
      </div>
    </>
  )
}
